import { useRouter } from 'next/router';
import {
  useMemo,
  useState,
  useEffect,
  KeyboardEvent,
  useCallback,
  useRef,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_STORES_NAMES_LIST } from 'api/store/queries';
import { SEARCH_STORES } from 'api/store/queries';
import { SEARCH_MERCH_PRODUCTS } from 'api/allMerch/queries';
import { SEARCH_MEMORABILIA } from 'api/memorabilia/queries';
import { SEARCH_STREAMS } from 'api/streams/queries';
import { SEARCH_EXPERIENCES } from 'api/experiences/queries';
import { GET_MEDIA_POSTS } from 'api/mediaPost/queries';
import { SEARCH_PERFORMED } from 'api/analytics/mutations';
// Types
import { SortKey } from 'components/common2/Sorting/Sorting';
import {
  GetStoresNamesList,
  GetStoresNamesListVariables,
} from 'api/store/types/GetStoresNamesList';
import {
  SearchStores,
  SearchStoresVariables,
} from 'api/store/types/SearchStores';
import {
  SearchMemorabilia,
  SearchMemorabiliaVariables,
} from 'api/memorabilia/types/SearchMemorabilia';
import {
  SearchStreams,
  SearchStreamsVariables,
} from 'api/streams/types/SearchStreams';
import {
  SearchExperiences,
  SearchExperiencesVariables,
} from 'api/experiences/types/SearchExperiences';
import {
  GetMediaPosts,
  GetMediaPostsVariables,
} from 'api/mediaPost/types/GetMediaPosts';
import {
  StoreStatus,
  StreamStatus,
  UserRole,
  SortDirection,
  StoresOrderBy,
  ExperienceOrderBy,
  ExperienceStatus,
  MemorabiliaProductType,
} from 'api/graphql-global-types';
import {
  SearchMerchProducts,
  SearchMerchProductsVariables,
} from 'api/allMerch/types/SearchMerchProducts';
import {
  SearchLocation,
  SearchResultType,
} from 'api/graphql-analytics-global-types';
import {
  SearchPerformed,
  SearchPerformedVariables,
} from 'api/analytics/types/SearchPerformed';
import analyticsClient from 'api/analyticsClient';
// Helpers
import { getStreamerName } from 'helpers/streams';
import {
  getIsMemorabiliaAndProductSrpPage,
  getIsMemorabiliaSrpPage,
  getIsProductSrpPage,
} from 'helpers/searchBar';
// Ui2
import Select from 'ui2/Select/Select';
// Components
import Option from './components/Option/Option';
// Styles
import styles from './SearchBar.module.scss';

type SearchBarProps = {
  wrapperClassName?: string;
  inModal?: boolean;
  sortKey?: SortKey;
  onSearch?: () => void;
  location: SearchLocation;
};

type SearchBarOptionProps = {
  name: string;
  searchTerm: string;
  value: string;
  label: string;
  hashtags?: string[];
  id: string;
  type: SearchResultType;
};

type SearchBarGroupProps = {
  label: string;
  options: SearchBarOptionProps[];
};

type FilterOptionOption = {
  label: string;
  value: string;
  data: SearchBarOptionProps;
};

const SearchBar = ({
  wrapperClassName,
  inModal,
  sortKey,
  onSearch,
  location,
}: SearchBarProps) => {
  const {
    storesListFilter,
    searchKey,
    search,
    setSearch,
    hideAthletesFilter,
    hideOrganizationsFilter,
  } = useFiltersContext();
  const { query, pathname } = useRouter();

  const isMemorabiliaSrpPage = getIsMemorabiliaSrpPage(query);
  const isProductSrpPage = getIsProductSrpPage(query);

  const isProductAndMemorabiliaSrpPage = getIsMemorabiliaAndProductSrpPage(
    query,
    pathname
  );

  const filtersContextSearchValue = search?.[searchKey || ''] || '';

  const [searchValue, setSearchValue] = useState<string>(
    filtersContextSearchValue
  );
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  // used to prevent Search Performed API call on initial mount, as a way of optimization
  // API will be called only after first click inside the search bar by checking for "isFirstSearch" and if search bar is focused
  const isFirstSearch = useRef(true);

  // "hideOrganizationsFilter" as a temporary flag of the "athletes" page
  const isAthletePage = !!hideOrganizationsFilter;
  // "hideAthletesFilter" as a temporary flag of the "organizations" page
  const isOrgPage = !!hideAthletesFilter;
  const isProductPage = !isAthletePage && !isOrgPage;

  const inputMerchVariables = useMemo(
    () => ({
      direction: SortDirection.ASC,
      limit: 25,
      offset: 0,
      ...(isProductAndMemorabiliaSrpPage && {
        productTypes: [
          MemorabiliaProductType.Product,
          MemorabiliaProductType.Memorabilia,
        ],
      }),
      ...(isMemorabiliaSrpPage && {
        productTypes: [MemorabiliaProductType.Memorabilia],
      }),
      ...(isProductSrpPage && {
        productTypes: [MemorabiliaProductType.Product],
      }),

      ...(searchKey && {
        ...(isProductPage
          ? {
              searchTerm: searchValue,
            }
          : {
              [searchKey]: searchValue,
            }),
      }),
    }),
    [
      searchKey,
      searchValue,
      isProductPage,
      isProductAndMemorabiliaSrpPage,
      isProductSrpPage,
      isMemorabiliaSrpPage,
    ]
  );

  const inputVariables = useMemo(
    () => ({
      direction: SortDirection.ASC,
      limit: 25,
      ...(storesListFilter && {
        [storesListFilter]: true,
      }),
      ...(searchKey && {
        ...(isProductPage
          ? {
              searchTerm: searchValue,
            }
          : {
              [searchKey]: searchValue,
            }),
      }),
    }),
    [isProductPage, storesListFilter, searchKey, searchValue]
  );

  const [searchStores, { data: storeData }] = useLazyQuery<
    SearchStores,
    SearchStoresVariables
  >(SEARCH_STORES, {
    fetchPolicy: 'network-only',
  });

  const [searchStreams, { data: streamsData }] = useLazyQuery<
    SearchStreams,
    SearchStreamsVariables
  >(SEARCH_STREAMS, {
    fetchPolicy: 'network-only',
  });

  const [searchMerchProducts, { data: merchData }] = useLazyQuery<
    SearchMerchProducts,
    SearchMerchProductsVariables
  >(SEARCH_MERCH_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const [searchMemorabilia, { data: memorabiliaData }] = useLazyQuery<
    SearchMemorabilia,
    SearchMemorabiliaVariables
  >(SEARCH_MEMORABILIA, {
    fetchPolicy: 'network-only',
  });

  const [searchExperiences, { data: experiencesData }] = useLazyQuery<
    SearchExperiences,
    SearchExperiencesVariables
  >(SEARCH_EXPERIENCES, {
    fetchPolicy: 'network-only',
  });

  const [searchMediaPosts, { data: mediaPostsData }] = useLazyQuery<
    GetMediaPosts,
    GetMediaPostsVariables
  >(GET_MEDIA_POSTS, {
    fetchPolicy: 'network-only',
  });

  const [searchStoreNames, { data: storeNamesData }] = useLazyQuery<
    GetStoresNamesList,
    GetStoresNamesListVariables
  >(GET_STORES_NAMES_LIST, {
    fetchPolicy: 'network-only',
  });

  const [analyticsSearch] = useMutation<
    SearchPerformed,
    SearchPerformedVariables
  >(SEARCH_PERFORMED, {
    client: analyticsClient,
  });

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const lastSearchTerm = useRef<string>('');
  const trimmedSearchedValue = searchValue.trim();
  const isNewSearchTerm: boolean =
    trimmedSearchedValue !== lastSearchTerm.current &&
    trimmedSearchedValue !== filtersContextSearchValue;

  const handleSendAnalytics = (
    id: string | null = null,
    searchType: SearchResultType | null = null
  ) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    if (isNewSearchTerm && trimmedSearchedValue) {
      analyticsSearch({
        variables: {
          input:
            id && searchType
              ? {
                  term: trimmedSearchedValue,
                  location: location,
                  searchResult: { srType: searchType, srId: id },
                }
              : {
                  term: trimmedSearchedValue,
                  location: location,
                },
        },
      });
    }
  };

  const initiateAnalyticsWithDebounce = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      handleSendAnalytics();
      lastSearchTerm.current = trimmedSearchedValue;
    }, 8000);
  };

  const performSearch = useCallback(async () => {
    if (
      [
        'Amas',
        'Merch',
        'Streams',
        'Products',
        'Experiences',
        'MediaPosts',
      ].includes(sortKey || '')
    ) {
      searchStores({
        variables: {
          storeRoles: [
            UserRole.Athlete,
            UserRole.Organization,
            UserRole.ContentCreator,
          ],
          input: {
            orderBy: StoresOrderBy.storeName,
            ...inputVariables,
          },
        },
      });

      if (sortKey === 'Streams') {
        searchStreams({
          variables: {
            input: {
              limit: 25,
              withCopies: false,
              direction: SortDirection.ASC,
              streamStatus: [
                StreamStatus.Active,
                StreamStatus.Scheduled,
                StreamStatus.Paused,
                StreamStatus.Interrupted,
                StreamStatus.Ended,
              ],
              storeStatus: StoreStatus.Active,
              ...(searchKey
                ? {
                    [searchKey]: searchValue,
                  }
                : {}),
            },
          },
        });
      }

      if (sortKey === 'Merch') {
        searchMerchProducts({
          variables: {
            input: {
              ...inputMerchVariables,
            },
          },
        });
      }

      if (sortKey === 'Products') {
        searchMemorabilia({
          variables: {
            input: {
              ...inputMerchVariables,
            },
          },
        });
      }

      if (sortKey === 'Experiences') {
        searchExperiences({
          variables: {
            input: {
              orderBy: ExperienceOrderBy.createdAt,
              direction: SortDirection.ASC,
              limit: 25,
              offset: 0,
              statuses: [ExperienceStatus.Active],
              storeStatuses: [StoreStatus.Active],
              ...(searchKey
                ? {
                    [searchKey]: searchValue,
                  }
                : {}),
            },
          },
        });
      }

      if (sortKey === 'MediaPosts') {
        searchMediaPosts({
          variables: {
            input: {
              storeStatuses: [StoreStatus.Active, StoreStatus.Pending],
              ...(searchKey
                ? {
                    [searchKey]: searchValue,
                  }
                : {}),
            },
          },
        });
      }
    }

    if (sortKey === 'Athletes') {
      searchStoreNames({
        variables: {
          storeRoles: [UserRole.Athlete],
          input: {
            orderBy: StoresOrderBy.storeName,
            ...inputVariables,
          },
        },
      });
    }

    if (sortKey === 'Organizations') {
      searchStoreNames({
        variables: {
          storeRoles: [UserRole.Organization, UserRole.ContentCreator],
          input: {
            orderBy: StoresOrderBy.storeName,
            ...inputVariables,
          },
        },
      });
    }

    // in case we ever split Organizations and have ContentCreator
    // if (sortKey === 'ContentCreator') {
    //   searchStoreNames({
    //     variables: {
    //       storeRoles: [UserRole.ContentCreator],
    //       input: {
    //         orderBy: StoresOrderBy.storeName,
    //         ...inputVariables,
    //       },
    //     },
    //   });
    // }
  }, [
    inputMerchVariables,
    inputVariables,
    searchExperiences,
    searchKey,
    searchMediaPosts,
    searchMemorabilia,
    searchMerchProducts,
    searchStoreNames,
    searchStores,
    searchStreams,
    searchValue,
    sortKey,
  ]);

  // initial API search
  useEffect(() => {
    performSearch();
    isFirstSearch.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // debounced API search
  useEffect(() => {
    if (isNewSearchTerm && !isFirstSearch.current) {
      initiateAnalyticsWithDebounce();
    }
    const timerId = setTimeout(() => {
      performSearch();
    }, 300);

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey, searchValue, performSearch]);

  useEffect(() => {
    setSearchValue(filtersContextSearchValue);
  }, [filtersContextSearchValue]);

  // send analytics if search input is blurred and stop debounce timer
  useEffect(() => {
    if (isNewSearchTerm && !searchFocused) {
      handleSendAnalytics();
      lastSearchTerm.current = trimmedSearchedValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFocused]);

  const loadOptions = () => {
    const groupedOptions: SearchBarGroupProps[] = [];
    const options: SearchBarOptionProps[] = [];

    if (
      [
        'Amas',
        'Merch',
        'Streams',
        'Products',
        'Experiences',
        'MediaPosts',
      ].includes(sortKey || '')
    ) {
      const storeNameOrganizationOptions: SearchBarOptionProps[] = [];
      const storeNameContentCreatorOptions: SearchBarOptionProps[] = [];
      const storeNameAthleteOptions: SearchBarOptionProps[] = [];
      const merchTitleOptions: SearchBarOptionProps[] = [];
      const productTitleOptions: SearchBarOptionProps[] = [];
      const streamNameOptions: SearchBarOptionProps[] = [];
      const experienceTitleOptions: SearchBarOptionProps[] = [];
      const mediaPostOptions: SearchBarOptionProps[] = [];

      storeData?.stores?.entities?.forEach((store) => {
        const name = `${store?.firstName || ''} ${store?.lastName || ''}`;
        const storeName = store.storeDetails?.storeName || '';
        const role = store.role || '';
        const id = store.id || '';

        if (role === UserRole.Organization) {
          storeNameOrganizationOptions.push({
            name,
            searchTerm: storeName,
            label: storeName,
            value: storeName + ' ' + searchValue,
            id,
            type: SearchResultType.Organization,
          });
        }

        if (role === UserRole.ContentCreator) {
          storeNameContentCreatorOptions.push({
            name,
            searchTerm: storeName,
            label: storeName,
            value: storeName + ' ' + searchValue,
            id,
            type: SearchResultType.ContentCreator,
          });
        }

        if (role === UserRole.Athlete) {
          storeNameAthleteOptions.push({
            name,
            searchTerm: storeName,
            label: storeName,
            value: storeName + ' ' + searchValue,
            id,
            type: SearchResultType.Athlete,
          });
        }
      });

      groupedOptions.push({
        label: 'Athletes',
        options: storeNameAthleteOptions,
      });

      groupedOptions.push({
        label: 'Organizations',
        options: storeNameOrganizationOptions,
      });

      groupedOptions.push({
        label: 'Content Creators',
        options: storeNameContentCreatorOptions,
      });

      if (sortKey === 'Streams') {
        streamsData?.streamsV2.entities?.forEach((item) => {
          const storeName = getStreamerName(item.store);
          const streamName = item.name || `${storeName}'s stream`;
          const id = item.id || '';

          streamNameOptions.push({
            name: streamName,
            searchTerm: streamName,
            label: streamName,
            value: streamName + ' ' + searchValue,
            id,
            type: SearchResultType.Stream,
          });
        });

        groupedOptions.push({
          label: 'Streams',
          options: streamNameOptions,
        });
      }

      if (sortKey === 'Merch') {
        merchData?.getMerchProducts.entities?.forEach(({ id, title }) => {
          merchTitleOptions.push({
            name: title,
            searchTerm: title,
            label: title,
            value: title + ' ' + searchValue,
            id: `${id}`,
            type: SearchResultType.Merch,
          });
        });

        groupedOptions.push({
          label: 'Merch',
          options: merchTitleOptions,
        });
      }

      if (sortKey === 'Products') {
        memorabiliaData?.getMemorabilia.entities?.forEach(
          ({ id, title, memorabiliaProductType }) => {
            const type =
              memorabiliaProductType === MemorabiliaProductType.Memorabilia
                ? SearchResultType.Memorabilia
                : SearchResultType.Product;

            productTitleOptions.push({
              name: title,
              searchTerm: title,
              label: title,
              value: title + ' ' + searchValue,
              id,
              type,
            });
          }
        );

        groupedOptions.push({
          label: 'Products',
          options: productTitleOptions,
        });
      }

      if (sortKey === 'MediaPosts') {
        mediaPostsData?.getMediaPosts.entities?.forEach(({ id, title }) => {
          mediaPostOptions.push({
            name: title,
            searchTerm: title,
            label: title,
            value: title + ' ' + searchValue,
            id,
            type: SearchResultType.MediaPost,
          });
        });

        groupedOptions.push({
          label: 'Media Posts',
          options: mediaPostOptions,
        });
      }

      if (sortKey === 'Experiences') {
        experiencesData?.getExperiences.entities?.forEach(({ id, title }) => {
          experienceTitleOptions.push({
            name: title,
            searchTerm: title,
            label: title,
            value: title + ' ' + searchValue,
            id,
            type: SearchResultType.Experience,
          });
        });

        groupedOptions.push({
          label: 'Experiences',
          options: experienceTitleOptions,
        });
      }

      return groupedOptions;
    }

    if (sortKey === 'Athletes') {
      storeNamesData?.stores?.entities?.forEach((store) => {
        const name = `${store?.firstName || ''} ${store?.lastName || ''}`;
        const storeName = store.storeDetails?.storeName || '';
        const id = store.id || '';
        const hashtags = store?.hashtags?.map((tag) => tag.name);

        options.push({
          name,
          searchTerm: storeName,
          label: storeName,
          value: `${storeName} ${name}`,
          id,
          hashtags,
          type: SearchResultType.Athlete,
        });
      });
    }

    if (sortKey === 'Organizations') {
      storeNamesData?.stores?.entities?.forEach((store) => {
        const name = `${store?.firstName || ''} ${store?.lastName || ''}`;
        const storeName = store.storeDetails?.storeName || '';
        const id = store.id || '';
        const hashtags = store?.hashtags?.map((tag) => tag.name);

        options.push({
          name,
          searchTerm: storeName,
          label: storeName,
          value: storeName,
          id,
          hashtags,
          type: SearchResultType.Organization,
        });
      });
    }

    return options;
  };

  const options = loadOptions();

  const debouncedUpdateSearchKeyword = useDebouncedCallback(
    (value: string = searchValue) => {
      setSearch({
        ...search,
        ...(searchKey && { [searchKey]: value || '' }),
      });
    },
    300
  );

  const inputValue = searchValue;
  const optionValue = searchValue
    ? {
        label: searchValue,
        value: searchValue,
      }
    : null;

  const onInputChange = (value: string, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSearchValue(value || '');
      return value;
    }
    if (action.action === 'input-blur') {
      setSearchValue(searchValue);
      return searchValue;
    }
  };

  const onSearchApply = (option: SearchBarOptionProps) => {
    // checking if option exists-in case of clear, it will be null, otherwise it will be an object
    if (option) {
      // send analytics when user selects an autocomplete option along with relevant data (search type and id)
      handleSendAnalytics(option.id, option.type);
      lastSearchTerm.current = option.label;
    }
    setSearchValue(option?.searchTerm || '');
    debouncedUpdateSearchKeyword(option?.searchTerm || '');
    if (onSearch) {
      onSearch();
    }
    return option;
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      // send analytics if user ignores autocomplete suggestions and presses enter
      handleSendAnalytics();
      lastSearchTerm.current = trimmedSearchedValue;

      debouncedUpdateSearchKeyword();
      if (onSearch) {
        onSearch();
      }
    }
  };

  const filterOption = (option: FilterOptionOption): boolean => {
    const optionValues: string[] = option?.value
      ?.toLowerCase()
      ?.split(' ')
      .filter(Boolean);
    const optionHashtagsValues: string[] =
      option?.data?.hashtags?.map((tag) => tag.toLowerCase()) || [];
    const searchValues: string[] = searchValue
      ?.toLowerCase()
      ?.split(/[^a-z0-9]+/i)
      .filter(Boolean);

    const searchValueMatchesHashtags: boolean = searchValues?.some((s) =>
      optionHashtagsValues?.some((o) => o?.includes(s))
    );

    if (searchValue && searchValueMatchesHashtags) {
      return true;
    }

    return searchValue
      ? searchValues?.some((s) => optionValues?.some((o) => o?.includes(s)))
      : true;
  };

  return (
    <div className={cn(styles.searchBarWrapper, wrapperClassName)}>
      <Select
        className={styles.searchBarSelect}
        placeholder="Search"
        components={{ Option }}
        defaultOptions
        getOptionLabel={(option: SearchBarOptionProps) => option.label}
        getOptionValue={(option: SearchBarOptionProps) => option.value}
        id="storeNameAsyncSelect"
        inputId="storeNameAsyncSelectInput"
        filterOption={filterOption}
        options={options}
        onInputChange={onInputChange}
        onChange={onSearchApply}
        onKeyDown={onKeyDown}
        value={optionValue}
        defaultValue={optionValue}
        inputValue={searchValue}
        defaultInputValue={inputValue}
        cacheOptions
        isClearable={Boolean(searchValue?.length)}
        dropdownIndicator="search"
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        styles={
          inModal
            ? {
                menu: (provided) => ({
                  ...provided,
                  position: 'relative',
                  border: 'none',
                  boxShadow: 'none',
                }),
              }
            : {}
        }
      />
    </div>
  );
};

export default SearchBar;
